import {Grid} from "@mui/material";
import React, {useState} from "react";
import "./styles.css"

export default function MediaItem({key,image,selectedImage,setSelectedImage}){

    return(
        <Grid key={key}>
            <img className={selectedImage.toString().includes(image.url) ? "select-media-thumbnail-selected":"select-media-thumbnail"} src={image.url} alt="Loading..." onClick={()=>{
                setSelectedImage(image.url);
            }}/>
        </Grid>
    );
}