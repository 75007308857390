import React, {useEffect, useState} from "react";
import Timeline from "../Timeline/Timeline";
import TabNavItem from "./TabNavItem";
import TabContent from "./TabContent";
import {fetchCategoriesData} from "../../Services/firebase";
import "./styles.css"
import CircularProgressIndicator from "../CircularProgressIndicator/CircularProgressIndicator";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandPointer} from "@fortawesome/free-solid-svg-icons";
import {COLORS} from "../../constants/constants";
import {useMediaQuery, useTheme} from "@mui/material";

const Tabs = () => {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [activeTab, setActiveTab] = useState("");
    const [timelineData,setTimelineData] = useState([])
    const [isLoading,setIsLoading] = useState(true);


    useEffect(
         ()=>{
            fetchCategoriesData().then((categorisedData)=>{
                setTimelineData(categorisedData);
                setActiveTab("2016");
                setIsLoading(false);
             });
            if(isSmallScreen){
                setTimeout(() => {
                const box = document.getElementById('hand-pointer');
                if(box !== null){
                    box.style.display = 'none';
                }
            }, 10000); // 👈️ time in milliseconds
            }
        }
    ,[])

    if(isLoading){
        return (<div className="Tabs"><CircularProgressIndicator/></div>);
    }else{
         return (
             <div className="Tabs" id="tabs">
                 <ul className="nav">
                     {timelineData.map((item,index)=>{
                         return(<TabNavItem key={index} title={2016+index+""} id={2016+index+""} activeTab={activeTab} setActiveTab={setActiveTab}/>);
                     })}
                 </ul>
                 {isSmallScreen ? <FontAwesomeIcon id="hand-pointer" icon={faHandPointer} size="xl" className="hand-pointer" color={COLORS.primary}/>:<></>}


                 <div className="outlet">
                     {timelineData.map((item,index)=>{
                         if(item != null){
                             return(
                                 <TabContent key={index} id={2016+index+""} activeTab={activeTab}>
                                     <Timeline timelineData={item}/>
                                 </TabContent>);
                         }
                         return <></>
                     })}
                 </div>
             </div>
         );
    }
}

export default Tabs;