import TimelineItem from './TimelineItem'
import React from "react";
import "./styles.css"
function Timeline  ({timelineData}) {

    return (
        timelineData.length > 0 && (
            <div className="timeline-container">
                {timelineData.map((data, idx) => {
                    if(data != null){
                        return(<TimelineItem data={data} key={idx}/>);
                    }
                    return (<></>);
                })}
            </div>
        )
    );
}

export default Timeline