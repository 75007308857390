import React from "react";
import Tabs from "../../../Components/Tabs/Tabs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons'
import {COLORS, STRINGS} from '../../../constants/constants.js'
import ContactUsSection from "../About/ContactUs/ContactUsSection";
import "./styles.css"
import MetaHead from "../../../MetaHead/MetaHead";
import BarChart from "../Management/Statistics/MyCharts/BarChart";

function LandingPage () {

    const handleClickScroll = () => {
        const element = document.getElementById('tabs');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
            <div className="landing-page-wrapper">
                <MetaHead title={STRINGS.websiteTitle+"-"+STRINGS.landingPageTitle} description="موقع أرشيف الجولان - الصفحة الرئيسيّة" type="website"/>
                <div className="landing-section">
                    <div className="website-desc-wrapper">
                        <div className="website-desc">
                            <h1 className="website-title">{STRINGS.websiteTitle}</h1>
                            <p className="website-sub-title">{STRINGS.landingSectionTitle}</p>
                        </div>
                        <FontAwesomeIcon icon={faArrowCircleDown} onClick={handleClickScroll} size="3x" bounce style={{cursor: "pointer",color:COLORS.primary,border:`${COLORS.darkTextColor} 0.2rem solid`,borderRadius:"50%"}}/>
                    </div>
                </div>
                <Tabs />
                <ContactUsSection/>
            </div>
    );
}

export default LandingPage