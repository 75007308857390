import "./styles.css"
import {Box,Input,TextField} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {getCurrentUser, signIn} from "../../../../Services/firebase";
import { useState} from "react";
import CircularProgressIndicator from "../../../../Components/CircularProgressIndicator/CircularProgressIndicator";

export default function Login(){

    const [email,setEmail]=useState("");
    const [password,setPassword]=useState("");
    const navigate = useNavigate();
    const [isLoading,setIsLoading] =useState(false);

    const handleLogin = async (event)=>{
        event.preventDefault();
        setIsLoading(true);
        const result = await signIn(email,password);
        if(result !== null) {
            if(result === 'not-verified'){
                window.alert("Email not verified, Please check your email for verification link");
                setIsLoading(false)
            }else{
                window.alert("success");
                setIsLoading(false);
                const customUser=await getCurrentUser();
                navigate("/edara/posts-page",{state:{user:customUser}});
            }
        }else {
            setIsLoading(false);
            window.alert("Error while signing in, please try again later");
        }
    }

    return(
        <div className="login-wrapper">
            {isLoading ?
                <CircularProgressIndicator/>:
                <Box className="login-box">
                    <form className="login-form" onSubmit={handleLogin}>
                        <div className="login-form-title">
                            <h1>تسجيل الدخول</h1>
                        </div>
                        <TextField id="login-email" aria-describedby="login-email" type="email" placeholder="email@example.com" onChange={(event)=>{
                            setEmail(event.target.value)
                        }} />
                        <TextField id="login-password" aria-describedby="login-password" type="password" onChange={(event)=>{
                            setPassword(event.target.value);
                        }}/>

                        <Input id="login-submit" aria-describedby="login-submit" type="submit" value="تسجيل الدخول" />
                    </form>
                </Box>
            }
        </div>
    );
}