import React from "react";
import ReactQuill from "react-quill";
import QuillToolbar, { modules, formats } from "./QuillToolbar";
import "react-quill/dist/quill.snow.css";
import "./styles.css"

export const Editor = ({value,handleChange}) => {

  return (
    <div className="quill-wrapper">
      <QuillToolbar />
      <ReactQuill
        theme="snow"
        value={value}
        onChange={handleChange}
        placeholder={"Write something awesome..."}
        className="react-quill"
        modules={modules}
        formats={formats}
      />
    </div>
  );
};

export default Editor;