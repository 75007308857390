import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { NavLink, useMatch, useResolvedPath} from "react-router-dom";
import {COLORS} from "../../constants/constants";
export default function NavItem({icon,to,label,isExpanded,state}){

    const resolvedPath =useResolvedPath(to)
    const isActive = useMatch({path: resolvedPath.pathname, end: true});

    return(
        <li className={isActive ? "sidebar-item active" : "sidebar-item"}>
            <NavLink to={to} className="sidebar-item-icon" state={state}>
                <FontAwesomeIcon icon={icon} size="xl" color={isActive ? COLORS.darkTextColor:COLORS.primaryShade1}/>
            </NavLink>
            <NavLink to={to} className={isExpanded?"sidebar-item-label":"sidebar-closed"}>{label}</NavLink>
        </li>
    );
}