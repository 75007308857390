import "./styles.css"
import {useLocation} from "react-router-dom";

export default function SinglePost(){

    const location = useLocation();
    const {postTitle,postDate,postContent} = location.state;

    return(
        <div className="single-post-wrapper">

            <h3 className="single-post-title">{postTitle}</h3>

            <span className="single-post-date">{postDate}</span>
ß
            <p>
                {postContent}
            </p>

        </div>
    );
}