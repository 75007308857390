import React, {useEffect, useState} from "react";
import './App.css';
import './constants/constants.css'
import LandingPage from "./Templates/SinglePage/LandingPage/LandingPage";
import About from "./Templates/SinglePage/About/About";
import Posts from "./Templates/SinglePage/Management/Posts/Dashboard/Posts"
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import Navbar from "./Components/Navbar/Navbar";
import {Navigate, Route, Routes} from "react-router-dom";
import ManagementPage from "./Templates/SinglePage/Management/Management";
import Settings from "./Templates/SinglePage/Management/Settings/Settings";
import Error404 from "./Templates/SinglePage/Error404";
import Appearance from "./Templates/SinglePage/Management/Appearance/Appearance";
import NewPost from "./Templates/SinglePage/Management/Posts/NewPost/NewPost";
import EditPost from "./Templates/SinglePage/Management/Posts/EditPost/EditPost";
import SinglePost from "./Templates/SinglePost/SinglePost";
import {auth, getCurrentUser} from "./Services/firebase"
import {onAuthStateChanged} from "firebase/auth";
import Login from "./Templates/SinglePage/Management/Login/Login";
import AboveHeader from "./Components/Navbar/AboveHeader";
import NavbarWrapper from "./Components/Navbar/NavbarWrapper";
import Statistics from "./Templates/SinglePage/Management/Statistics/Statistics";
import Users from "./Templates/SinglePage/Management/Users/Users";
import MediaLibrary from "./Templates/SinglePage/Management/MediaLibrary/MediaLibrary";

function App() {

    const [customUser,setCustomUser] = useState(null);

    useEffect(
        ()=>{
            onAuthStateChanged(auth,
                 (user)=>{
                if(user){
                    localStorage.setItem("isUserSignedIn",true);
                    getCurrentUser().then((customUser)=>{
                        setCustomUser(customUser);
                    });
                }else{
                    setCustomUser(null);
                    localStorage.removeItem("isUserSignedIn");
                }
            });
            }
        , [customUser]
    );


    return (
        <div className="App">
            <header className="App-header">
                <AboveHeader user={customUser}/>
                <NavbarWrapper>
                    <Navbar user={customUser}/>
                </NavbarWrapper>
            </header>

            <main className="main">
                <Routes>
                    <Route exact path="/" element={<LandingPage/>}/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path={"/single-post"} element={<SinglePost/>}/>
                    <Route path="/edara" element={localStorage.getItem("isUserSignedIn")?<ManagementPage user={customUser}/>:<Navigate replace to={"/login"}/>}>
                        <Route index element={<Posts />} />
                        <Route path="posts-page" element={<Posts />}/>
                        <Route path="media-library" element={<MediaLibrary />}/>
                         <Route path="edit-post" element={<EditPost/>}/>
                        <Route path="new-post" element={<NewPost/>}/>
                        <Route path="statistics" element={<Statistics/>}/>
                        <Route path="users" element={<Users/>}/>
                        <Route path="appearance" element={<Appearance/>}/>
                        <Route path="settings" element={<Settings />} />
                        <Route path="*" element={<Error404 />} />
                    </Route>
                </Routes>

            </main>
            <ScrollToTop/>
        </div>
    );
}

export default App;
