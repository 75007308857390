import {
    Box,
    CircularProgress,
    Container,
    Modal,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import React, {useState} from "react";
import "./styles.css"
import DialogActions from "@mui/material/DialogActions";

export default function ConfirmationDialog({title,message,modalVisible,confirmAction,denyAction}){


    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [loading,setLoading] = useState(false);

     const style = {
         position: 'absolute',
         top: '50%',
         left: isSmallScreen? '43%':'50%',
         transform: 'translate(-50%, -50%)',
         width: isSmallScreen ? '80%' : '30%',
         height:isSmallScreen? "50%":"35%",
         bgcolor: 'background.paper',
         border: '1px solid #b6d1e2',
         borderRadius:'1rem 1rem 1rem 1rem',
         boxShadow: 24,
         p: 2,
         direction:"rtl",
         overflow:"hidden",
         textAlign:"justify",
         backdrop:'rgba(255, 255, 255, 0.7)',
         overlay:'rgba(255, 255, 255, 0.7)'
     };

    return(
        <div className="confirmation-modal-wrapper">
            <Modal open={modalVisible} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Typography id="modal-modal-title"  variant="h6" component="h2">
                        {title}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{textAlign:"center"}}>
                        {loading?<CircularProgress />:message}
                    </Typography>
                    <DialogActions style={{textAlign:"center"}} className="confirmation-actions-bar">
                        <button className="confirmation-btn confirm-btn" onClick={()=> {
                            setLoading(true);
                            confirmAction().then(()=>{
                                setLoading(false);
                            });

                        }}>حذف</button>
                        <button className="confirmation-btn cancel-btn" onClick={denyAction}>إلغاء</button>
                    </DialogActions>
                </Box>
            </Modal>
        </div>
    );
}