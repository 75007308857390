import MediaToolsBar from "./MediaToolsBar";
import ImagesGrid from "./ImagesGrid";
import "./styles.css"
export default function MediaLibrary(){
    return(
        <div className="media-library-wrapper">
            <MediaToolsBar/>
            <ImagesGrid/>
        </div>
    );
}