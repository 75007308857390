import "./styles.css"
import React, {useEffect, useState} from "react";
import {addNewPost, getCurrentUser, uploadNewImage} from "../../../../../Services/firebase";
import Editor from "../Quill/Editor";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useLocation, useNavigate} from "react-router-dom";
import MetaHead from "../../../../../MetaHead/MetaHead";
import {COLORS} from "../../../../../constants/constants";
import {Box, Button, FormControlLabel, Modal, Switch, useMediaQuery, useTheme} from "@mui/material";
import SelectMediaLibraryImage from "../common/SelectMediaLibraryImage/SelectMediaLibraryImage";
import * as events from "events";
import Select from "react-select";
const NewPost = ()=>{
    const location = useLocation();
    const {user} = location.state;
    const convertedDate = new Date(Date.now());
    const dateString = convertedDate.getFullYear() + "-" + (convertedDate.getMonth()+1).toLocaleString('en-US',{
        minimumIntegerDigits:2,
        useGrouping:false
    })+"-"+convertedDate.getDate().toLocaleString('en-US',{
        minimumIntegerDigits:2,
        useGrouping:false
    });
    const [postTitle, setPostTitle] = useState("");
    const [postDate, setPostDate] = useState(dateString);
    const [postStatus, setPostStatus] = useState('Draft');
    const [postExcerpt, setPostExcerpt] = useState('');
    const [postFeaturedImage, setPostFeaturedImage] = useState('');
    const [postContent, setPostContent] = useState("");
    const [isUploading,setIsUploading] = useState(false);
    const [uploadOrSelectExisting,setUploadOrSelectExisting] = useState(false);
    const [selectedImage,setSelectedImage] = useState('');
    const [featuredImageModalShow,setFeaturedImageModalShow] = useState(false);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();


      const style = {
         position: 'absolute',
         top: '50%',
         left: '50%',
         transform: 'translate(-50%, -50%)',
         width: isSmallScreen ? '90%' : '80%',
         height:isSmallScreen? "95%":"80%",
         bgcolor: COLORS.primaryShade2,
         border: '1px solid #b6d1e2',
         boxShadow: 24,
         p: 2,
         direction:"rtl",
         overflow:"auto",
         textAlign:"center",
     };

      const options=[
          {
              label:"Draft",
              value:"Draft"
          },
          {
              label: "Published",
              value: "Published"
          }
      ]

    function handlePostTitleChange(event){
        setPostTitle(event.target.value);
    }

    function handlePostDateChange(event){
        const convertedDate = Date.parse(event.target.value);
        setPostDate(convertedDate);
    }

     function handlePostStatusChange(event){
        setPostStatus(event.value);
    }
     function handlePostExcerptChange(event){
        setPostExcerpt(event.target.value);
    }

    function handlePostContentChange(event){
        setPostContent(event);
    }

    function handleFormSubmit(event){
        event.preventDefault();
        addNewPost(postTitle,Date.parse(postDate),postExcerpt,postFeaturedImage,postContent,user.firstName+" "+user.lastName,postStatus).then((result)=>{
            window.alert(result);
        });
    }

    return(
        <div className="new-post-wrapper">
            <MetaHead title={"إضافة حدث"}  description="صفحة الإدارة - إضافة حدث" type="website"/>

            <div className="add-new-box">
                <h3 className="new-post-page-title">إضافة حدث</h3>
                <form className="add-new-form" onSubmit={handleFormSubmit}>
                    <div className="submit-button">
                        <input className="form-item" type="submit" value="إضافة حدث" />
                    </div>
                    <div>
                        <input className="title-field form-item" type="text" aria-label="العنوان" title="العنوان" onChange={handlePostTitleChange} placeholder="العنوان"  required/>
                    </div>
                    <Editor value={postContent} handleChange={handlePostContentChange}/>

                </form>
            </div>

            <div className="add-new-side-bar">

                <button className="exit-to-dashboard-button" onClick={()=>{
                    navigate(-1);
                }}>العودة للأحداث
                    <FontAwesomeIcon icon={faClose} color={COLORS.darkTextColor} size="xl" className="close-icon"/>
                </button>
                <div className="edit-author-field">
                    <label>المستخدم :</label>
                    <span>{user.firstName+" "+user.lastName}</span>
                </div>
                <div>

                    <Select options={options} onChange={handlePostStatusChange} defaultValue="Draft" placeholder="Draft"/>
                </div>

                <label>التاريخ</label>
                <div className="edit-date-field">
                    <input value={postDate} className="edit-form-item" type="date" onChange={handlePostDateChange} />
                </div>
                <div className="edit-featured-image-field">
                           {postFeaturedImage&& <img src={uploadOrSelectExisting?URL.createObjectURL(postFeaturedImage):postFeaturedImage} alt="Featured Image"/>}
                    <Modal open={featuredImageModalShow} onClose={()=>{
                        setFeaturedImageModalShow(false);
                    }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box aria-label="modal-modal-description" sx={style}>

                            <Button style={{backgroundColor:`${COLORS.primaryShade1}`,color:`${COLORS.darkTextColor}`,fontFamily:"Cairo",padding:".5rem .8rem",margin:"1rem"}} onClick={()=>{
                                setUploadOrSelectExisting(!uploadOrSelectExisting);
                            }}>{uploadOrSelectExisting && "اختيار من قاعدة البيانات"} {!uploadOrSelectExisting && "رفع صورة جديدة"}</Button>

                            {(!uploadOrSelectExisting) &&
                                <>
                                    <h2>اختيار من قاعدة البيانات</h2>
                                    <SelectMediaLibraryImage selectedImage={selectedImage} setSelectedImage={setSelectedImage}/>
                                </>}
                            {uploadOrSelectExisting &&<div className="featured-image-file-browser">
                                    <label id="file-input-label" htmlFor="file-input">رفع صورة جديدة</label>
                                    <input id="file-input" name="file-input" type='file' onChange={ (event)=>{
                                        setPostFeaturedImage(event.target.files[0]);
                                              setIsUploading(true);
                                              uploadNewImage(event.target.files[0]).then((result)=>{
                                                  setIsUploading(false);
                                                  setFeaturedImageModalShow(false);
                                                  window.alert(`upload is done, result: ${result}`);
                                              });
                                    }}/>
                            </div>}

                            <div className="select-media-button">
                                {(!uploadOrSelectExisting) && <Button onClick={async ()=>{
                                    setPostFeaturedImage(selectedImage);
                                    setFeaturedImageModalShow(false);
                                }}>اختيار</Button>}

                                {uploadOrSelectExisting && <></>}

                            </div>
                        </Box>
                    </Modal>
                    <Button onClick={()=>{
                        setFeaturedImageModalShow(!featuredImageModalShow);
                    }}>
                        تعديل الصورة
                    </Button>
                </div>
                <label>الوصف</label>
                <div className="edit-excerpt-field">
                     <textarea value={postExcerpt} className="edit-form-item" aria-label="الوصف" title="الوصف" onChange={handlePostExcerptChange} placeholder="الوصف"/>
                </div>
            </div>

        </div>
    );
}

export default NewPost