import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import {Alert} from "@mui/material";
import "./styles.css"

export default function AlertDialog({message,open,handleClose}) {

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Alert onClose={handleClose}>{message}</Alert>
          </DialogContentText>
        </DialogContent>

      </Dialog>
    </div>
  );
}