import "./styles.css"
import {useEffect, useState} from "react";
import {fetchUsersData} from "../../../../Services/firebase";
import CircularProgressIndicator from "../../../../Components/CircularProgressIndicator/CircularProgressIndicator";
import MetaHead from "../../../../MetaHead/MetaHead";
import {Table, TableHead} from "@mui/material";
import UsersTable from "./UsersTable";
import UsersToolsBar from "./UsersToolsBar";

export default function Users(){

    const [users,setUsers] = useState({});
    const [isLoading,setIsLoading]=useState(true);
    useEffect(()=>{
        fetchUsersData().then((usersCollection)=>{
            setUsers(usersCollection)
            setIsLoading(false);
        })
    },[])

    return(
        <div className="users-page-wrapper">

            <MetaHead title={"الفريق"}  description="صفحة الإدارة - الفريق" type="website"/>

            <div className="users-table-wrapper">
                <UsersToolsBar/>
                {isLoading ? (<CircularProgressIndicator/>) :( <UsersTable users={users}/> )}
            </div>
        </div>
    );
}