import "./styles.css"
import {STRINGS} from "../../../constants/constants";
import MetaHead from "../../../MetaHead/MetaHead";
import React from "react";
export default function HeaderSection(){

    return(
        <div className="header-section">
            <MetaHead title={STRINGS.aboutPageTitle} description="صفحة عن المشروع" type="website"/>
            <div className="header-title-wrapper">
                <h1 className="header-title">{STRINGS.aboutPageTitle}</h1>
            </div>

            <section className="about-us-paragraph">
                {STRINGS.aboutProject}
            </section>
        </div>
    );

}
