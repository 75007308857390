import {useLocation} from "react-router-dom";

export default function Error404(){

    const location = useLocation()
    const {errorMsg} = location.state;

    return(
        <div>
        <h1>
            Error 404 - No match found
        </h1>

        <p>
            {errorMsg}
        </p>
        </div>
    );
}