import "./styles.css"
import {useEffect, useState} from "react";
import {fetchAppearanceData} from "../../../../Services/firebase";
import {Input} from "@mui/material";
import ColorUnit from "./ColorUnit";
import MetaHead from "../../../../MetaHead/MetaHead";
import CircularProgressIndicator from "../../../../Components/CircularProgressIndicator/CircularProgressIndicator";
export default function Appearance({...props}){

    const [fontFamily,setFontFamily] = useState("")
    const [colors,setColors] = useState({})
    const [isLoading,setIsLoading] = useState(false);

    useEffect(()=>{
        setIsLoading(true)
        fetchAppearanceData().then((data)=>{
            setColors(data.colors);
            setFontFamily(data.fontFamily)
            setIsLoading(false)
        })
    },[])

    return(
        <div className="appearance-wrapper">
            {isLoading ? <CircularProgressIndicator/>:<>  <MetaHead title={"التصميم"}  description="صفحة الإدارة - التصميم" type="website"/>

            <h1>
                التصميم
            </h1>

            <div className="typography">
                <h2>الخط</h2>
                <input type="font" placeholder={fontFamily}/>
            </div>

            <div className="colors-palette">
                <div className="colors-palette-title">
                    <h2>لوحة الألوان</h2>
                </div>
                <ColorUnit color={colors.primary} name="primary"/>
                <ColorUnit color={colors.primaryShade1} name="primaryShade1"/>
                <ColorUnit color={colors.primaryShade2} name="primaryShade2"/>
                <ColorUnit color={colors.primaryShade2Opacity} name="primaryShade2Opacity"/>
                <ColorUnit color={colors.secondary} name="secondary"/>
                <ColorUnit color={colors.secondaryShade1} name="secondaryShade1"/>
                <ColorUnit color={colors.secondaryShade2} name="secondaryShade2"/>
                <ColorUnit color={colors.background} name="background"/>
                <ColorUnit color={colors.lightBackground} name="lightBackground"/>
                <ColorUnit color={colors.lighterBackground} name="lighterBackground"/>
                <ColorUnit color={colors.darkTextColor} name="darkTextColor"/>
                <ColorUnit color={colors.lightTextColor} name="lightTextColor"/>
                <ColorUnit color={colors.borderStrong} name="borderStrong"/>
                <ColorUnit color={colors.borderLight} name="borderLight"/>
                <ColorUnit color={colors.linkColor} name="linkColor"/>
                <ColorUnit color={colors.buttonColor} name="buttonColor"/>
            </div></>}

        </div>
    );
}