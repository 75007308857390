import "./styles.css"
import HeaderSection from "./HeaderSection";
import ContactUsSection from "./ContactUs/ContactUsSection";
import {STRINGS} from "../../../constants/constants";
import React from "react";
export default function About() {
    return(
        <div className="about-page-wrapper">

            <div className="content-wrapper">
                <HeaderSection/>

                <div className="desc-section">
                    <div className="goal-desc">
                        <h2>أهدافنا وتطلّعاتنا</h2>
                        {STRINGS.projectGoals}
                    </div>

                    <div className="target-audience-desc">
                        <h2>فريق العمل وجمهور الهدف</h2>
                        <p>
                            {STRINGS.targetAudienceAndteamMembers}
                        </p>
                    </div>

                    <div className="site-content-desc">
                        <h2>محتوى الموقع</h2>
                        <p>
                            {STRINGS.websiteContentDescription}
                        </p>
                    </div>
                    
                    <div className="design-desc">
                        <h2>اللغة البصريّة</h2>
                        <p>
                            {STRINGS.visuals}
                        </p>
                    </div>
                </div>

            </div>
              <ContactUsSection/>
        </div>
    );
}