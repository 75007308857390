import "./styles.css"
import {fetchFromFile} from "../../../../Services/firebase";
import {useEffect} from "react";
import MetaHead from "../../../../MetaHead/MetaHead";
export default function Settings(){


    return(
        <div className="settings-wrapper">
            <MetaHead title={"الإعدادات"}  description="صفحة الإدارة - الإعدادات" type="website"/>
            <h1>Settings</h1>
        </div>
    );
}