import "./styles.css"
import {useEffect, useState} from "react";
import {fetchAllEvents, getCurrentUser, updateEvents} from "../../../../../Services/firebase";
import CircularProgressIndicator from "../../../../../Components/CircularProgressIndicator/CircularProgressIndicator";
import {NavLink, useLocation} from "react-router-dom";

import {
    Button,
    Checkbox,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import Select from "react-select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd} from "@fortawesome/free-solid-svg-icons";
import TableItem from "./TableItem";
import {COLORS} from "../../../../../constants/constants";
import MetaHead from "../../../../../MetaHead/MetaHead";

const Posts=()=>{

    const location = useLocation();
    const {user} = location.state;
    const [postsList,setPostsList] = useState([]);
    const [filteredPostsList,setFilteredPostsList] = useState([]);
    const [selectedFilter,setSelectedFilter] = useState("");
    const [selectedAction,setSelectedAction] = useState("");
    const [isLoading,setIsLoading] = useState(true);


    const yearOptions = [
        { value: 'Select', label: 'Select' },
        { value: '2016', label: '2016' },
        { value: '2017', label: '2017' },
        { value: '2018', label: '2018' },
        { value: '2019', label: '2019' },
        { value: '2020', label: '2020' },
        { value: '2021', label: '2021' },
        { value: '2022', label: '2022' },
        { value: '2023', label: '2023' }
    ];

     useEffect(()=>{
        fetchAllEvents().then((events)=>{
           setPostsList(events);
           setFilteredPostsList(events);
           setIsLoading(false);
        });

    },[])

    const handleFilterEvent = (event)=>{

        if (selectedFilter === "Select"){
            setFilteredPostsList(postsList);
            return;
        }
        const filtered = postsList.filter((post)=>{
            const convertedDate = (post.date).toDate();
            if(convertedDate.getFullYear() === parseInt(selectedFilter)){
                return true;
            }else {
                return false;
            }
        })

        setFilteredPostsList(filtered);
    }

    const handleFilterChange = (event) =>{
        setSelectedFilter(event.value);
    }


    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')

        a.download = fileName
        a.href = window.URL.createObjectURL(blob)

        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const exportToJson = e => {
        e.preventDefault()

        const now = new Date();
        const date =new Intl.DateTimeFormat('en-US', {day: '2-digit', month: '2-digit'}).format(now);
        const dateFlipped = date.split("/")
        const dateFinal = dateFlipped[1] + dateFlipped[0]+now.getFullYear();

        downloadFile({
            data: JSON.stringify(filteredPostsList),
            fileName: `jawlanarchiveevents${dateFinal}.json`,
            fileType: 'text/json',
        })
    }

    const exportToCsv = e => {
        e.preventDefault()
        const now = new Date();
        const date =new Intl.DateTimeFormat('en-US', {day: '2-digit', month: '2-digit'}).format(now);
        const dateFlipped = date.split("/")
        const dateFinal = dateFlipped[1] + dateFlipped[0]+now.getFullYear();
        // Headers for each column
        let headers = ['Id,Event Title,Event Date,Event Status,Event Author, Event Excerpt, Featured Image,  EventContent']

        // Convert events data to a csv
        let eventsCsv = filteredPostsList.reduce((acc, event) => {
            acc.push([event.id, event.title,  new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(event.date.toDate()),event.status,event.author,event.excerpt,event.featuredImage, event.content].join(','))
            return acc
        }, [])

        downloadFile({
            data: [...headers, ...eventsCsv].join('\n'),
            fileName: `jawlanarchiveevents${dateFinal}.csv`,
            fileType: 'text/csv',
        })
    }

    return(
        <div className="posts-page-wrapper">

            <MetaHead title="الأحداث" description="صفحة الإدارة - الأحداث" type="website"/>

                <div className="posts-controls-wrapper">
                    <div className="posts-buttons-wrapper">
                        <div className="download-buttons-wrapper">
                            <button className="download-events-csv" onClick={exportToCsv}>CSV</button>
                            <button className="download-events-json" onClick={exportToJson}>JSON</button>
                            <label className="download-events-label">تنزيل الأحداث</label>
                        </div>
                    <div className="add-new-button-wrapper">
                        <NavLink className="add-new-button" to="/edara/new-post" state={{user:user}}><FontAwesomeIcon icon={faAdd} size="lg" style={{margin:"auto",borderRadius:"50%",color:`${COLORS.darkTextColor}`,padding:".2rem"}}/>إضافة حدث</NavLink>
                        {/*<Button onClick={async ()=>{*/}
                        {/*    const result = await updateEvents();*/}
                        {/*    window.alert(result);*/}
                        {/*}}>test</Button>*/}
                    </div>
                    </div>

                    <div className="tools-bar-wrapper">
                        <div className="selected-posts-num">
                             <span>{`selected (${filteredPostsList.length
                             }) \\ all (${postsList.length})`}</span>
                         </div>

                        <div className="filter-action">
                            <label>بحث</label>
                            <Select className="select-container" options={yearOptions} onChange={handleFilterChange}/>
                            <button className="action-button"  onClick={handleFilterEvent}>بحث</button>
                        </div>

                    </div>

                </div>
            <div className="posts-table-wrapper">
                {isLoading ? (<CircularProgressIndicator/>) :

                     <TableContainer component={Paper}>
                         <Table aria-label="posts table" className="posts-table" >
                             <TableHead style={{position:"sticky",top:"0"}}>
                                 <TableRow>
                                     <TableCell className="table-header-item" align="center"><label>التاريخ</label></TableCell>
                                     <TableCell className="table-header-item" align="right"><label>العنوان</label></TableCell>
                                     <TableCell className="table-header-item" align="center"><label>الحالة</label></TableCell>
                                     <TableCell className="table-header-item" align="center"><label>المستخدم</label></TableCell>
                                     <TableCell className="table-header-item" align="center"><label>اللغة</label></TableCell>
                                 </TableRow>
                             </TableHead>

                             <TableBody id="table-header-body" className="table-header-body" >
                                 {
                                     filteredPostsList.map((post,index)=>{
                                         let  convertedDate =(post.date).toDate();
                                         const date =new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(convertedDate);

                                         return(
                                             <TableRow key={index} className="table-body-row">
                                                  <TableCell width="10%" className="table-data post-date" align="center" style={{fontFamily:"Cairo",fontSize:"1rem"}}>{date}</TableCell>
                                                 <TableCell className="table-data" width="65%">
                                                     <TableItem post={post} user={user} setPostsList={(posts)=>{
                                                         setPostsList(posts);
                                                     }} />
                                                 </TableCell>
                                                 <TableCell width="10%" className="table-data post-status" align="center" style={{fontFamily:"Cairo",fontSize:"1rem"}}>{post.status}</TableCell>
                                                 <TableCell width="10%" className="table-data post-author" align="center" style={{fontFamily:"Cairo",fontSize:"1rem"}}>{post.author}</TableCell>
                                                 <TableCell width="10%" className="table-data post-lang" align="center" style={{fontFamily:"Cairo",fontSize:"1rem"}}>العربية</TableCell>
                                             </TableRow>
                                         );})
                                 }
                             </TableBody>
                         </Table>
                     </TableContainer>
                }
            </div>
        </div>
    );
}

export default Posts