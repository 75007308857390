import {Box, Button, Grid, Modal, useMediaQuery, useTheme} from "@mui/material";
import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import {COLORS} from "../../../../constants/constants";
import {deleteImage, uploadNewImage} from "../../../../Services/firebase";
import {useNavigate} from "react-router";

export default function ImageGridItem({key,image}){

    const [imageDetailsModalShow,setImageDetailsModalShow] = useState(false);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    const style = {
         position: 'absolute',
         top: '50%',
         left: '50%',
         transform: 'translate(-50%, -50%)',
         width: isSmallScreen ? '90%' : '90%',
         height:isSmallScreen? "95%":"95%",
         bgcolor: COLORS.lightBackground,
         border: '1px solid #b6d1e2',
         boxShadow: 24,
         p: 2,
         direction:"rtl",
         overflow:"auto",
         textAlign:"justify",
     };

    return(
        <Grid key={key}>
            <Modal
                  open={imageDetailsModalShow}
                  onClose={()=>{setImageDetailsModalShow(false)}}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                      <div id="modal-modal-title">
                          <h2>
                              {image.name}
                          </h2>
                          <FontAwesomeIcon icon={faClose} color={COLORS.buttonColor} onClick={()=>{setImageDetailsModalShow(false);}} size="xl"/>
                      </div>
                      <div className="media-delete-button">
                          <Button onClick={async ()=>{
                              const result = await deleteImage(image);
                              window.alert(result);
                              navigate(0);
                          }}>حذف</Button>
                      </div>
                    <div id="modal-modal-description" sx={{ mt: 2 }} className="modal-post-content">
                        <img src={image.url} width="100%" height="auto"/>
                    </div>
                  </Box>
                </Modal>

            <img className="media-thumbnail" src={image.url} alt="Loading..." onClick={()=>{
                setImageDetailsModalShow(true);
            }}/>
        </Grid>
    );
}