import React, {useEffect, useState} from 'react'
import {Pie} from 'react-chartjs-2'
import {Chart as ChartJS} from "chart.js/auto"
import {fetchCategoriesData} from "../../../../../Services/firebase";
import {COLORS} from "../../../../../constants/constants";

export default function PieChart() {

    const [yearsData,setYearsData] = useState({
            labels:0,
            datasets: [{
                label:"عدد الأحداث",
                data:0,
            }]
        });
    useEffect(()=>{
        let tmp = [];
        fetchCategoriesData().then((data)=>{
            setYearsData(getData(data));
        });
    },[])

    const getData=(categorisedEvents)=>{
        if(categorisedEvents === null){
            return null;
        }
        const colors = categorisedEvents.map((year)=>`rgb(74, 170, 87,${0.5+(year.length/100)})`);
        return({
            labels:categorisedEvents.map((yearData,index)=>2016+index),
            datasets: [{
                label:"عدد الأحداث",
                data:categorisedEvents.map((yearData)=>yearData.length),
                backgroundColor:colors,
                colors:colors
            }]
        });
    }

  return (
      <div style={{width: '100%', height: '100%'}}>
          <Pie data={yearsData} style={{height:"auto",width:"100%",margin:"auto"}}/>
      </div>
  );
}