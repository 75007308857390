import "./styles.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faSignal,faClose} from '@fortawesome/free-solid-svg-icons'
import {useState} from "react";
import {CustomLink} from "./CustomLink";
import logo from "../../assets/logo250x250.png"
import {NavLink} from "react-router-dom";
import SocialIcons from "./SocialIcons";
import {COLORS} from "../../constants/constants";

export default function Navbar({user}){
    const [isNavExpanded, setIsNavExpanded] = useState(false);
    const handleClick = ()=>{
        setIsNavExpanded(!isNavExpanded);
    }

    return(
        <div className="navbar-wrapper">

            <nav className="navbar">
                 <div className="signal-btn">
                     <FontAwesomeIcon className={isNavExpanded ? "open":"closed"} icon={faSignal} color={COLORS.primaryShade1} size="xl"  rotation={270} onClick={()=>{
                         setIsNavExpanded(!isNavExpanded);
                     }}/>
                     <FontAwesomeIcon className={isNavExpanded ? "close":"open"} icon={faClose} color={COLORS.primaryShade1} size="xl"  rotation={270} onClick={()=>{
                         setIsNavExpanded(!isNavExpanded);
                     }}/>
                 </div>
                <div className="logo-link-wrapper-wrapper">
                    <NavLink className="logo-link-wrapper" to="/">
                        <img className="logo" alt="Site Logo" src={logo}/>
                    </NavLink>
                </div>

                 <div id="nav-menu" className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"}>
                     <ul className="navigation-menu-list">
                         <CustomLink to="/" onClick={handleClick}>الرئيسيّة</CustomLink>
                         <CustomLink to="/about" onClick={handleClick}>عن المشروع</CustomLink>
                         {/*<CustomLink to="/edara" onClick={handleClick}>الإدارة</CustomLink>*/}
                     </ul>
                 </div>

                <SocialIcons/>
             </nav>
        </div>
    );
}