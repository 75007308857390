import "./styles.css"
import {STRINGS} from "../../../../constants/constants";
export default function ContactUsSection(){

    return(
         <div className="contact-section-wrapper">
             <div className="contact-section">
                 <h2 className="contact-title">تواصلوا معنا</h2>
                 <p>
                     {STRINGS.contactUs}
                 </p>
                 <span><h2>{STRINGS.contactEmail}</h2></span>
             </div>
         </div>
    );
}