import "./styles.css"
import {STRINGS} from "../../../../constants/constants";
import BarChart from "./MyCharts/BarChart";
import LineChart from "./MyCharts/LineChart";
import PieChart from "./MyCharts/PieChart";
import MetaHead from "../../../../MetaHead/MetaHead";
export default function Statistics(){

    return(
        <div className="statistics-page-wrapper">

            <MetaHead title={"إحصائيّات"}  description="صفحة الإدارة - إحصائيّات" type="website"/>

            <div className="statistics-page-title">
                {STRINGS.statisticsPageTitle}
            </div>

            <div className="dashboard-wrapper">
                <div className="chart-wrapper">
                    <BarChart/>
                </div>
                <div  className="chart-wrapper">
                    <PieChart/>
                </div>
                <div className="chart-wrapper">
                    <LineChart />
                </div>
            </div>

        </div>
    );
}