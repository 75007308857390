import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faInstagram} from '@fortawesome/free-brands-svg-icons'
import {COLORS} from "../../constants/constants";
export default function SocialIcons(){


    return(
        <div className="social-icons-wrapper">
            <a href="https://www.facebook.com/Jawlanculturedays" target="_blank"  rel="noreferrer">
                <FontAwesomeIcon icon={faFacebook} size="2xl" color={COLORS.primaryShade1}/>
            </a>

            <a href="https://www.instagram.com/jawlanculturaldays/" target="_blank"  rel="noreferrer">
                <FontAwesomeIcon icon={faInstagram} size="2xl" color={COLORS.primaryShade1}/>
            </a>
        </div>
    );
}