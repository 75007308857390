import "./styles.css"
import {customSignOut} from "../../Services/firebase";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignOut} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router";

export default function AboveHeader({user}){
    const navigate = useNavigate();
    if(user !== null){
        return (
            <div className="above-header-wrapper">
                <div className="admin-navbar">
                    <div className="admin-navbar-list">
                            <NavLink to="/edara/posts-page" state={{user:user}}>
                                الإدارة
                            </NavLink>

                            <NavLink to="/" >
                                زيارة الموقع
                            </NavLink>
                    </div>
                </div>
                <div className="user-status-wrapper">
                    <div className="status-email">{user.email}</div>
                    <div className="signout-btn-wrapper">

                        <FontAwesomeIcon icon={faSignOut} title="Sign Out" size="sm" color="#4ca442" onClick={
                            async ()=>{
                                const result =await customSignOut();
                                window.alert(result);
                                navigate(0)
                            }
                        }/>
                    </div>
                </div>
            </div>
        );
    }else {
        return (<></>);
    }
}