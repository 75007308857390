import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";

const NavbarWrapper = ({children})=>{

    const location = useLocation();

    const [showNavBar,setShowNavBar] = useState(false);

    useEffect(()=>{
        if(location.pathname.includes( '/edara')){
            setShowNavBar(false);
        }else{
            setShowNavBar(true);
        }
    },[location]);

    return(
        <div>
            {showNavBar && children}
        </div>
    )
}

export default NavbarWrapper