import {Box, Button, Checkbox, Modal, TableCell, TableRow, useMediaQuery, useTheme} from "@mui/material";
import {COLORS} from "../../../../constants/constants";
import { useNavigate } from 'react-router'
import {customSignOut, deleteCustomUser, updateCustomUser} from "../../../../Services/firebase";
import React, {useState} from "react";
import Select from "react-select";

export default function UsersTableUnit({user}){

    const [userFirstName,setUserFirstName] = useState(user.firstName)
    const [userLastName,setUserLastName] = useState(user.lastName)
    const [userRole,setUserRole] = useState(user.role)
    const [modalVisible,setModalVisible]=useState(false);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate()

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isSmallScreen ? '90%' : '30%',
        height:isSmallScreen? "90%":"60%",
        bgcolor: COLORS.lightBackground,
        border: '1px solid #b6d1e2',
        borderRadius:"1rem",
        boxShadow: 24,
        p: 2,
        direction:"rtl",
        overflow:"auto",
        textAlign:"center",
    };

    const roleOptions = [
        { value: 'admin', label: 'admin' },
        { value: 'editor', label: 'editor' },
        { value: 'subscriber', label: 'subscriber' },
    ];

    const handleEditUserSubmit = async (event)=>{
        event.preventDefault();
        const result = await updateCustomUser(user.id,userFirstName,userLastName,userRole);
        setModalVisible(false);
        window.alert(result);
        navigate(0);
    }

    return(
           <TableRow key={user.uid} >
               <TableCell align="center" width={10} className="users-table-checkbox-cul">
                   <Checkbox style={{color:`${COLORS.darkTextColor}`}} onChange={(event, checked)=>{
                       if(checked){
                       }else{
                       }
                   }}/>
               </TableCell>
               <TableCell align="center" style={{fontFamily:"Cairo",fontSize:"1rem"}}>
                   {user.firstName + " " +user.lastName}
               </TableCell>
               <TableCell align="center" style={{fontFamily:"Cairo",fontSize:"1rem"}}>
                   {user.email}
               </TableCell>
               <TableCell align="center" style={{fontFamily:"Cairo",fontSize:"1rem"}}>
                   {user.role}
               </TableCell>
               <TableCell align="center" style={{fontFamily:"Cairo",fontSize:"1rem"}}>
                   {user.id}
               </TableCell>
               <TableCell align="center" style={{fontFamily:"Cairo",fontSize:"1rem",display:"flex",gap:".5rem",flexGrow:"0"}}>
                   <Modal
                       open={modalVisible} onClose={()=>{setModalVisible(false)}}
                       aria-labelledby="modal-modal-title"
                       aria-describedby="modal-modal-description"
                   >
                       <Box sx={style}>
                           <div id="modal-modal-title">
                               <h2>
                                   {`تعديل: ${user.email}`}
                               </h2>
                           </div>
                           <div id="modal-modal-description" sx={{ mt: 2 }} className="modal-post-content">
                               <form className="edit-user-form" onSubmit={handleEditUserSubmit}>
                                   <input value={userFirstName} onChange={(event)=>{
                                       setUserFirstName(event.target.value);
                                   }} type="text" placeholder="الإسم" required/>
                                   <input value={userLastName} onChange={(event)=>{
                                       setUserLastName(event.target.value);
                                   }} type="text" placeholder="العائلة" required/>
                                   <Select options={roleOptions} onChange={(event)=>{setUserRole(event.value)}} type="text" placeholder={user.role} required/>
                                   <Button type="submit" style={{fontFamily:"Cairo",fontWeight:"bold",backgroundColor:`${COLORS.buttonColor}`,color:`${COLORS.lightTextColor}`}}>تعديل</Button>
                               </form>
                           </div>
                               <Button onClick={
                                   ()=>{
                                       setUserFirstName(user.firstName);
                                       setUserLastName(user.lastName);
                                       setUserRole(user.role);
                                       setModalVisible(false);
                                   }
                               } style={{fontFamily:"Cairo",fontWeight:"bold",backgroundColor:"firebrick",color:`${COLORS.lightTextColor}`}}>إلغاء</Button>
                       </Box>
                   </Modal>

                   <Button style={{fontFamily:"Cairo",backgroundColor:"dodgerblue",color:`${COLORS.lightTextColor}` ,fontWeight:"bold"}} onClick={()=>{
                       setModalVisible(!modalVisible);
                   }}>تعديل</Button>
                   <Button style={{fontFamily:"Cairo",backgroundColor:"firebrick",color:`${COLORS.lightTextColor}` ,fontWeight:"bold"}} onClick={()=>{
                       deleteCustomUser(user.id).then((result)=>{
                           if(result.data !== null) {
                               window.alert(result.data);
                           }else{
                               window.alert("Result message is null, refresh to check if user was deleted");
                           }
                           customSignOut().then(()=>{
                               navigate("/login");
                           });

                       }).catch((e)=>{
                           window.alert(e.message)
                       });
                   }}>حذف</Button>
               </TableCell>
           </TableRow>
    );
}