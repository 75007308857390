import "./styles.css"
import React, {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {updatePost, uploadNewImage} from "../../../../../Services/firebase";
import Editor from "../Quill/Editor";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import MetaHead from "../../../../../MetaHead/MetaHead";
import {Box, Button, Modal, useMediaQuery, useTheme} from "@mui/material";
import {COLORS} from "../../../../../constants/constants";
import SelectMediaLibraryImage from "../common/SelectMediaLibraryImage/SelectMediaLibraryImage";
import Select from "react-select";
import Option from "react-select";

export default function EditPost(){

    const location = useLocation()
    const {postId,postTitle,postDate,postExcerpt,postFeaturedImage,postContent,postAuthor,postStatus,user} = location.state;
    const convertedDate = new Date(postDate.seconds*1000 + postDate.nanoseconds/100000)
    const dateString = convertedDate.getFullYear() + "-" + (convertedDate.getMonth()+1).toLocaleString('en-US',{
        minimumIntegerDigits:2,
        useGrouping:false
    })+"-"+convertedDate.getDate().toLocaleString('en-US',{
        minimumIntegerDigits:2,
        useGrouping:false
    });

    const [editPostTitle, setPostTitle] = useState(postTitle);
    const [editPostDate, setPostDate] = useState(dateString);
    const [editPostStatus, setPostStatus] = useState(postStatus);
    const [editPostExcerpt, setPostExcerpt] = useState(postExcerpt);
    const [editPostFeaturedImage, setPostFeaturedImage] = useState(postFeaturedImage);
    const [editPostContent, setPostContent] = useState(postContent);
    const [isUploading,setIsUploading] = useState(false);
    const [uploadOrSelectExisting,setUploadOrSelectExisting] = useState(false);
    const [selectedImage,setSelectedImage] = useState('');
    const [featuredImageModalShow,setFeaturedImageModalShow] = useState(false);
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


    const style = {
         position: 'absolute',
         top: '50%',
         left: '50%',
         transform: 'translate(-50%, -50%)',
         width: isSmallScreen ? '90%' : '80%',
         height:isSmallScreen? "95%":"80%",
         bgcolor: COLORS.primaryShade2,
         border: '1px solid #b6d1e2',
         boxShadow: 24,
         p: 2,
         direction:"rtl",
         overflow:"auto",
         textAlign:"center",
     };


      const options=[
          {
              label:"Draft",
              value:"Draft"
          },
          {
              label: "Published",
              value: "Published"
          }
      ]

    function handlePostTitleChange(event){
        setPostTitle(event.target.value);
    }

    function handlePostDateChange(event){
        setPostDate(event.target.value);
    }

    function handlePostStatusChange(event){
        setPostStatus(event.value);
    }

    function handlePostContentChange(event){
        setPostContent(event);
    }

    async function handleFormSubmit(event){
        event.preventDefault();
        const result = await updatePost(postId,editPostTitle,editPostDate,editPostExcerpt,editPostFeaturedImage,editPostContent,postAuthor,postStatus);
        window.alert(result);
    }

    return(
        <div className="edit-post-wrapper">
            <MetaHead title={editPostTitle + "تعديل-"}  description="صفحة الإدارة - تعديل" type="website"/>
            <div className="edit-box">
                <form className="edit-form" onSubmit={handleFormSubmit}>
                    <div className="edit-form-item submit-edit-button">
                        <button type="submit"> حفظ التغييرات</button>
                    </div>

                    <input value={editPostTitle} className="edit-title-field edit-form-item" type="text" aria-label="العنوان" title="العنوان" onChange={handlePostTitleChange} placeholder="العنوان"  required/>
                    <div className="custom-content-editor">
                        <Editor value={editPostContent} handleChange={handlePostContentChange}/>
                    </div>
                </form>
            </div>

            <div className="edit-side-bar">
                <button className="exit-to-dashboard-button" onClick={async ()=>{
                    navigate(-1);
                }}>العودة للأحداث
                    <FontAwesomeIcon icon={faClose} color={COLORS.darkTextColor} size="xl" className="close-icon"/>
                </button>
                <div className="edit-author-field">
                    <label>المستخدم :</label>
                    <span>{postAuthor}</span>
                </div>
                <div className="edit-post-status-select">
                    <Select options={options} placeholder={editPostStatus} onChange={handlePostStatusChange} sx={{width: 200, height: 50}}/>
                </div>
                <div className="edit-date-field-wrapper">
                    <label>التاريخ</label>
                    <div className="edit-date-field">
                        <input value={editPostDate} className="edit-form-item" type="date" onChange={handlePostDateChange} />
                    </div>
                </div>

                <div className="edit-featured-image-field">
                            <img src={editPostFeaturedImage} alt="Featured Image"/>
                    <div className="featured-image-crud-buttons">
                          <Modal open={featuredImageModalShow} onClose={()=>{
                        setFeaturedImageModalShow(false);
                    }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box aria-label="modal-modal-description" sx={style}>

                            <Button style={{backgroundColor:`${COLORS.primaryShade1}`,color:`${COLORS.darkTextColor}`,fontFamily:"Cairo",padding:".5rem .8rem",margin:"1rem"}} onClick={()=>{
                                setUploadOrSelectExisting(!uploadOrSelectExisting);
                            }}>{uploadOrSelectExisting && "اختيار من قاعدة البيانات"} {!uploadOrSelectExisting && "رفع صورة جديدة"}</Button>

                            {(!uploadOrSelectExisting) &&
                                <>
                                    <h2>اختيار من قاعدة البيانات</h2>
                                    <SelectMediaLibraryImage selectedImage={selectedImage} setSelectedImage={setSelectedImage}/>
                                </>}
                            {uploadOrSelectExisting &&<div className="featured-image-file-browser">
                                    <label id="file-input-label" htmlFor="file-input">رفع صورة جديدة</label>
                                    <input id="file-input" name="file-input" type='file' onChange={ (event)=>{
                                        setPostFeaturedImage(event.target.files[0]);
                                              setIsUploading(true);
                                              uploadNewImage(event.target.files[0]).then((result)=>{
                                                  setIsUploading(false);
                                                  window.alert(`upload is done, result: ${result}`);
                                                  setFeaturedImageModalShow(false);
                                              });
                                    }}/>
                            </div>}

                            <div className="select-media-button">
                                {(!uploadOrSelectExisting) && <Button onClick={async ()=>{
                                    setPostFeaturedImage(selectedImage);
                                    setFeaturedImageModalShow(false);
                                }}>اختيار</Button>}

                                {uploadOrSelectExisting && <></>}

                            </div>
                        </Box>
                    </Modal>
                        <Button onClick={()=>{
                            setFeaturedImageModalShow(!featuredImageModalShow);
                        }}>تعديل</Button>
                        <Button onClick={()=>{
                            setPostFeaturedImage('');
                        }}>إزالة</Button>
                    </div>
                </div>
                <div className="edit-excerpt-field-wrapper">
                    <label>الوصف</label>
                    <div className="edit-excerpt-field">
                        <textarea value={editPostExcerpt} className="edit-form-item" aria-label="الوصف" title="الوصف" onChange={handlePostTitleChange} placeholder="الوصف"/>
                    </div>
                </div>
            </div>
        </div>
    );
}