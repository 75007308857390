// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    orderBy,
    query,
    setDoc,
    Timestamp,
    updateDoc
} from "firebase/firestore";
import {
    createUserWithEmailAndPassword,
    getAuth,
    sendEmailVerification,
    signInWithEmailAndPassword,
    signOut
} from "firebase/auth";

import {getDownloadURL, getStorage, listAll, ref, uploadBytesResumable,deleteObject} from "firebase/storage"
import {getFunctions, httpsCallable} from "firebase/functions";


// https://firebase.google.com/docs/web/setup#available-libraries

// web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCqY3RSxqpW00uexhNCVSw2noE7Nh0cQQs",
  authDomain: "turbinestimeline.firebaseapp.com",
  projectId: "turbinestimeline",
  storageBucket: "turbinestimeline.appspot.com",
  messagingSenderId: "125631172011",
  appId: "1:125631172011:web:70ef01e028acdd87f1d6ee"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const functions = getFunctions(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const db = getFirestore(app);


/*-------------Authentication-Functions-------------*/
export const signUp = async (email,password,firstName,lastName)=>{
    try {
        const user = await createUserWithEmailAndPassword(auth,email,password);
        const result = await addCustomUser(user.user.uid,firstName,lastName,email);
        if(result.toString().includes("success")){
            await sendEmailVerification(user.user);
        }
        return result;
    }catch (e){
        return e.message;
    }
}

export const addCustomUser = async ( uid,firstName,lastName,email)=>{
    try {
        const data = {"firstName":firstName,"lastName":lastName,"email":email,"role":"subscriber"}
        await setDoc(doc(db,"users",uid),data);
        return 'success';
    }catch (e){
        return e.message;
    }
}

export const updateCustomUser = async (uid,firstName,lastName,role)=>{
    try {
        const data = {"firstName":firstName,"lastName":lastName,"role":role}
        await updateDoc(doc(db,"users",uid),data);
        return 'success';
    }catch (e){
        return e.message;
    }
}

export const deleteCustomUser = async ( uid)=>{
    const deleteUser = httpsCallable(functions,"deleteUser");
    return await deleteUser({uid: uid});
}

export const signIn= async (email,password)=> {

    try{
        const user =await signInWithEmailAndPassword(auth, email, password);

        if(!user.user.emailVerified){
            await customSignOut();
            await sendEmailVerification(user.user);
            return 'not-verified'
        }

        // Signed in
        return user;

    } catch(error) {
        console.log(error.message)
        return null;
    };
}

export const customSignOut=async ()=>{
    try {
        await signOut(auth);
        return 'success';
    }catch (error){
        return error;
    }
}

export const fetchUsersData = async ()=>{

    try{
        const usersDocs = await getDocs(query(collection(db,"users"),orderBy("firstName","asc")));
        const usersArray = usersDocs.docs.map((doc)=>{return {id:doc.id,...doc.data()}
    });
    return usersArray;
    }catch (e){
        return e;
    }
}

export const getCurrentUser= async ()=>{
    const firebaseUser = auth.currentUser;
    const customUser = await getDoc(doc(db,"users",firebaseUser.uid));

    return customUser.data();
}

/*-------------Firestore: Events-Functions-------------*/
export const updateEvents = async ()=>{

    try{
        let uids = [];
        const collectionRef = await getDocs(collection(db,"events"));
        for(let i=0; i<collectionRef.docs.length;i++){
            uids.push(collectionRef.docs[i].id);
         }

        for(let i=0;i<uids.length;i++){
            await updateDoc(doc(db,"events",uids[i]),{"status":"Published"});
        }

        return 'success';
    }catch (e){
        return e.message;
    }


}

export const fetchCategoriesData = async  ()=>{
    try {
        let processedData = []
        let i=0;
        const eventsCollectionRef = collection(db,"events");
        const queryResult = query(eventsCollectionRef, orderBy("date","asc"));
        const querySnapshot = await getDocs(queryResult);
        let currentYear =2016;

        querySnapshot.docs.map((doc)=> {

                const year = doc.data().date.toDate().getFullYear();
                const event = {id:doc.id, ...doc.data()}

                if(year === currentYear){
                    if(processedData[i] == null){
                        let tempArray = [];
                        tempArray.push(event);
                        processedData[i]=tempArray;
                    }else{
                        processedData[i].push(event);
                    }
                }else{
                    currentYear=year
                    i++;
                    let tempArray = [];
                    tempArray.push(event);
                    processedData[i]=tempArray;
                }
                return 'success';
        });

        return processedData;
    }catch (error){
        console.log(error)
        return error;
    }
}

export const fetchAllEvents = async ()=>{

    const eventsDocs = await getDocs(query(collection(db,"events"),orderBy("date","asc")));
    const eventsArray = eventsDocs.docs.map((doc)=>{
        return {id:doc.id,...doc.data()}
    });
    return eventsArray;
}

export const fetchAllBackUpEvents = async ()=>{

    const eventsDocs = await getDocs(query(collection(db,"backup"),orderBy("date","asc")));
    const eventsArray = eventsDocs.docs.map((doc)=>{
        return {id:doc.id,...doc.data()}
    });
    return eventsArray;
}

export const addNewPost = async (postTitle,postDate,postExcerpt,postFeaturedImage,postContent,postAuthor,postStatus)=>{
     const timestamp = Timestamp.fromMillis(postDate)

     const data = {
         title: postTitle,
         date: timestamp,
         excerpt:postExcerpt,
         featuredImage:postFeaturedImage,
         content: postContent,
         author:postAuthor,
         status:postStatus
     };

     try{
         await addDoc(collection(db,"events"), data);
         return 'success';
     }catch (error){
         return error;
     }
}

export const backUpPosts = async ()=>{
    const data= await fetchAllEvents();

    try{
        for(let i=0;i<data.length;i++){
            await addDoc(collection(db,"backup"),{title:data[i].title,date:data[i].date,content:data[i].content});
        }

        return 'success';
    }catch (e){
        return e;
    }
}

export const revertToBackUp = async()=>{
     const data= await fetchAllBackUpEvents();

    try{
        for(let i=0;i<data.length;i++){
            await addDoc(collection(db,"events"),{title:data[i].title,date:data[i].date,content:data[i].content});
        }

        return 'success';
    }catch (e){
        return e;
    }
}

export const updatePost = async (postId,postTitle,postDate,postExcerpt,postFeaturedImage,postContent,postAuthor,postStatus)=>{

    const dateObj =  Date.parse(postDate);
    const convertedDate = new Date(dateObj);
    const data = {"title":postTitle,"date":Timestamp.fromDate(convertedDate),"excerpt":postExcerpt,"featuredImage":postFeaturedImage,"content":postContent,"author":postAuthor,"postStatus":postStatus}

    try{
        await updateDoc(doc(db,"events",postId),data);
        return 'success';
    }catch (e){
        return e;
    }
}

export const bulkDelete= async(...docsIds)=>{
    try {
        let result;
        let success = true;
        for (let id in docsIds){
           result=await deletePost(id);
           if(result !== "success"){
               success=false;
               break;
           }
        }
        if (success){
            return 'success';
        }else{
            return result;
        }
    }catch (e){
        return e;
    }
}

export const deletePost= async (docId)=>{
    try{
        await deleteDoc(doc(db,'events',docId));
        return 'success';
    }catch (e){
        return e;
    }
}

/*-------------Firestore: Configuration-Functions-------------*/
export const fetchAppearanceData= async ()=>{
    try{
        const documentSnapshot = await getDoc(doc(db,"constants","appearance"));
        const config = {id:documentSnapshot.id,...documentSnapshot.data()};
        return config;
    }catch (e){
        return e;
    }
}

/*-------------Storage: MediaLibrary-Functions-------------*/

export const fetchMediaLibrary= async ()=>{

    try{
        const images=[];
        const listRef = ref(storage, '/MediaLibrary');
        const result = await listAll(listRef);
        for(let i=0; i< result.items.length;i++){
            const downloadUrl = await getDownloadURL(ref(storage,result.items[i].fullPath));
            images.push({name:result.items[i].name,url:downloadUrl,});
        }
        return images;
    }catch (e){
        console.log(e.message)
        return null;
    }
}

export const uploadNewImage = async (image)=>{
    try{
        const storageRef = ref(storage,"/MediaLibrary/"+image.name);
        const result = await uploadBytesResumable(storageRef, image);
        return result.snapshot.state;
    }catch (e){
        return e.message;
    }

}

export const deleteImage = async (image)=>{
    const desertRef = ref(storage, "/MediaLibrary/"+image.name);
    try{
        await deleteObject(desertRef);
        return 'File deleted successfully';
    }catch (e){
        return e.message;
    }
}