export const COLORS = {
    primary:"#4AAA57",
    primaryShade1:"#85c48e",
    primaryShade2:"#baddc0",
    primaryShade2Opacity: "rgba(186, 221, 192,0.5)",
    secondary:"#5AFFB8",
    secondaryShade1:"#8dfecd",
    secondaryShade2:"#befee3",
    secondaryOpacity:"rgba(90, 255, 184,0.5)",
    background: "#E6FFF8",
    lightBackground:"#edfef9",
    lighterBackground:"#f5fefc",
    darkTextColor:"#14281D",
    lightTextColor:"#E6FFF8",
    borderStrong:"#65716b",
    borderLight:"#aab0ad",
    linkColor:"#4AAA57",
    buttonColor:"#4AAA57",
}

export const STRINGS={
    websiteTitle:"أرشيف الجولان",
    landingPageTitle:"الرئيسيّة",
    landingSectionTitle:"تاريخ النضال ضد مشروع توربينات الرياح",
    contactEmail:"jawlan.culture67@gmail.com",
    aboutPageTitle:"عن المشروع",
    aboutProject:"هنالك أهميّة كبيرة للتوثيق وإتاحة المعلومات حول الأحداث التي نعاصرها ونعيشها، بلغتنا ووفق روايتنا؛ فالتوثيق هنا هو كتابة للسرديّة، وتتضاعف أهميته في سياق سياسات ممنهجة ومتواصلة، تسعى لمحوِ تاريخ وهويّة الجولان السوريّ المحتلّ من وعي سكّانه الأصليّين قبل غيرهم. في حالتنا، تكتسب الأرشفة والتوثيق معانٍ أبعدَ من مجرد \"تجميع وترتيب المعلومات والوثائق\"، وتصبح وسيلة من وسائل تحرير الوعي من هيمنة الرواية التي تحاول منظومات الاحتلال فرضها وتثبيتها. عندما نتمكّن من النظر للماضي، ينفتح أفق التفكير النقديّ، ويمكن، بتأثير ذلك، أن نكسر دائرة ردود الفعل الآنيّة ونتمكّن تالياً من التفكير في المستقبل. \n",
    projectGoals:"الهدف المباشر والدافع الأوّلي لإطلاق هذا المشروع، هو جمع وتركيز أحداث النضال الشعبيّ، والمراحل التي مرّ بها، خلال سنوات التصدّي لمشروع \"توربينات الرياح\" المزمع إقامته – وفرضه بالقوّة - على الأراضي الزراعيّة القليلة المتبقيّة بحوزة أهل الجولان، لتكون هذه الأحداث والوثائق متاحةً ومجمّعةً في قاعدة بيانات واحدة، للمطالعة والمراجعة والبناء عليها. رؤيتنا للموقع تطمح إلى تطويره ليشمل، مستقبلاً، مواضيعَ وقضايا تتعدّى توثيق \"مشروع المراوح\" إلى مواضيع أخرى حيويّة وتهمّ جمهورنا. نواصل حاليّاً العمل على تطوير صفحات الإدارة فيه، بهدف إتاحة المجال لتغذية وتعديل قاعدة البيانات، أمام أكبر عدد من الأشخاص، دون الحاجة لخبرات تخصّصيّة. \n",
    targetAudienceAndteamMembers:"يعمل على تطوير الموقع مجموعة من فريق مهرجان - أيّام الجولان الثقافيّة – ممّن لديهم/نّ مهارات في مجال البرمجة، بينما تمّ تجميع المواد من قبل مجموعة من النشطاء الذين واكبوا قضيّة المراوح منذ بداياتها. يتضمّن الفريق أيضاً خبرات تعمل على ترجمة المحتوى، ليتمّ إضافة نسخة إنكليزيّة، للقرّاء والباحثين الأجانب، في المستقبل.\n" +
        "جمهور الهدف هو أهل الجولان بالأساس، وفئات المعنيّين بهذه البيانات، لأغراض بحثيّة، بشكل عام. ولأنّ الموقع، بصورته الحاليّة، ليس نهائياً، فقد أخذنا بالحسبان إبقاء آفاق التطوير مفتوحةً على احتمالات التحديث والتنوّع وتعدّد الاحتياجات، وبشكل خاصّ للمهتمّين بهذه القضيّة. كما أن إضافة الترجمة للغة الانكليزيّة مستقبلا سوف تساهم في توسيع قاعدة جمهور الهدف. \n",
    websiteContentDescription:" بدأنا العمل على المشروع مطلع تمّوز الماضي، وكمرحلة أولى، وضعنا هدفاً، هو إنشاء بنية أساسيّة لموقع يوفّر شريطا زمنيّاً يستعرض أحداث \"مشروع المراوح\"، ليتمّ إطلاقه مع اختتام مهرجان أيّام الجولان الثقافيّة-٣، في السادس من آب، 2023. يحتوي الموقع، في الوقت الحاليّ، على صفحة \"وصف المشروع\"، وصفحة رئيسيّة فيها شريط يؤطّر \"مشروع المراوح\" وفق التسلسل الزمنيّ لأحداثه وتطوّراته، منذ عام 2016 وحتى تاريخ إطلاق الموقع. \n",
    visuals:"الفكرة الأساسيّة لتصميم الموقع هي عرض بصريّ لشريط تسلسل زمنيّ، يحتوي عناوين الأحداث، مرتّبةً حسب التاريخ ومصنّفةً حسب السنة. هذا التصميم يوفّر لمستخدمي الموقع تجربة سلسة عند تصفح الأحداث، ويسمح بوصول سريع للمحتوى. يمكن فتح محتوى الحدث في نفس الصفحة، والعودة منه، بعد إغلاقه، إلى ذات المكان في التسلسل الزمنيّ. هذه القاعدة من البيانات، عموماً، هي عبارة عن نصوص وروابط للمصادر والمراجع.. في بعض الحالات، تمّ إرفاق مستندات ذات صلة أو صوراً ووسائط أخرى؛ وهو محتوى عام تمّ نشره على شبكة الانترنت سابقاً. قمنا بتجميع البيانات والنصوص التي نشرت حول وخلال هذه الأحداث - كما هي - بعد مراجعتها، والتحقّق قدر الإمكان من دقّتها.",
    contactUs:"يرجى الانتباه أنّ هذا الموقع تمّ إنشاؤه بجهد تطوّعي، ومن المحتمل أن تكون بعض الأخطاء قد وقعت خلال العمل.\n" +
        "في حال تمّ العثور على خطأٍ ما أو مادّة غير دقيقة، أو إن أردتم مراسلتنا بخصوص إضافة مواد أو أيّة أسئلة أخرى، يرجى التواصل معنا على الحساب البريدي:\n",
    statisticsPageTitle:"إحصائيّات"

}