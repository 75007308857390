import {Grid} from "@mui/material";
import {useEffect, useState} from "react";
import {fetchMediaLibrary, fetchMediaUrls} from "../../../../Services/firebase";
import {COLORS} from "../../../../constants/constants";
import ImageGridItem from "./ImageGridItem";
import CircularProgressIndicator from "../../../../Components/CircularProgressIndicator/CircularProgressIndicator";

export default function ImagesGrid(){

    const [images,setImages] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

    useEffect(()=>{
        setIsLoading(true)
        fetchMediaLibrary().then((images)=>{
            setImages(images);
            setIsLoading(false);
        });
    },[])

    return(
        <div className="media-library-images-grid">
            {isLoading?<CircularProgressIndicator/>:<Grid container spacing={0}>
                {images && images.map((image,i)=>{
                    return(<ImageGridItem key={i} image={image} />);
                })}
            </Grid>}
        </div>
    );
}