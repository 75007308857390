import {Box, Button, Input, Modal, useMediaQuery, useTheme} from "@mui/material";
import Select from "react-select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faClose} from "@fortawesome/free-solid-svg-icons";
import {COLORS} from "../../../../constants/constants";
import React, {useState} from "react";
import { uploadNewImage} from "../../../../Services/firebase";
import parse from "html-react-parser";
import {useNavigate} from "react-router";
import CircularProgressIndicator from "../../../../Components/CircularProgressIndicator/CircularProgressIndicator";

export default function MediaToolsBar(){

    const [selectedBulkAction,setSelectedBulkAction] = useState('Select')
    const [newMediaFile,setNewMediaFile]= useState('');
    const [addMediaModalShow,setAddMediaModalShow] = useState(false);
    const [isUploading,setIsUploading] = useState(false);
    const [uploadProgress,setUploadProgress] = useState(0);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    const style = {
         position: 'absolute',
         top: '50%',
         left: '50%',
         transform: 'translate(-50%, -50%)',
         width: isSmallScreen ? '90%' : '50%',
         height:isSmallScreen? "95%":"50%",
         bgcolor: COLORS.primaryShade2,
         border: '1px solid #b6d1e2',
         boxShadow: 24,
         p: 2,
         direction:"rtl",
         overflow:"auto",
         textAlign:"justify",
     };

    const options = [
        {
            value:"Select",
            label:"Select"
        },
        {
            value:"Delete",
            label:"Delete"
        }
    ]
    return(
        <div className="media-tools-bar">
            <div className="add-new-media">
                 <Modal
                  open={addMediaModalShow}
                  onClose={()=>{setAddMediaModalShow(false)}}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                      <div id="modal-modal-title">
                          <h2>
                              تحميل ملف
                          </h2>
                          <FontAwesomeIcon icon={faClose} color={COLORS.buttonColor} onClick={()=>{setAddMediaModalShow(false);setNewMediaFile(null)}} size="xl"/>
                      </div>

                    <div id="modal-modal-description" sx={{ mt: 2 }} className="modal-post-content">
                        <input  type="file" onChange={(event)=>{
                            setNewMediaFile(event.target.files[0]);
                        }}/>
                        {isUploading && <div className="media-upload-progress">
                            <CircularProgressIndicator/>
                        </div>}
                    </div>
                      <div className="media-library-upload-button">
                          <Button onClick={async ()=>{
                              setIsUploading(true);
                              const result = await uploadNewImage(newMediaFile);
                              setIsUploading(false);
                              navigate(0);
                          }}>تحميل</Button>
                           {newMediaFile&& <img src={URL.createObjectURL(newMediaFile)}/>}
                      </div>

                  </Box>
                </Modal>
                <Button onClick={(event)=>{
                    setAddMediaModalShow(!addMediaModalShow);
                }}>إضافة</Button>
            </div>
            <div className="media-bulk-delete">
                <Select value={selectedBulkAction} options={options} onChange={(event)=>{
                    setSelectedBulkAction(event.value);
                }} placeholder="Select"/>
                <Button>تطبيق</Button>
            </div>
        </div>
    );
}