import {NavLink, useNavigate} from "react-router-dom";
import ConfirmationDialog from "../../../../../Components/Dialogs/ConfirmationDialog";
import {deletePost, fetchAllEvents} from "../../../../../Services/firebase";
import './styles.css'
import {useState} from "react";
import AlertDialog from "../../../../../Components/Dialogs/SuccessAlertMessage";
export default function TableItem({post,setPostsList,user}){

    const [deleteDialogOpen,setDeleteDialogOpen] = useState(false);
    const [resultMessage,setResultMessage] = useState("");
    const [alertOpen,setAlertOpen] = useState(false);
    const navigate = useNavigate()

    return(
        <div className="post-title-column">
            <p className="post-title">{post.title}</p>
            <div className="crud-row">
                <div className="edit-post-btn">
                    <NavLink to="/edara/edit-post"  state={{postId:post.id,postDate:post.date,postTitle:post.title,postContent:post.content,postExcerpt:post.excerpt,postFeaturedImage:post.featuredImage,postAuthor:post.author,postStatus:post.status,user}}>تعديل</NavLink>
                </div>
                <button onClick={()=>{
                    setDeleteDialogOpen(true)}} className="delete-post-btn">حذف</button>
                {alertOpen? <AlertDialog open={alertOpen} message={resultMessage} handleClose={()=>{
                    setAlertOpen(false);

                }}/>:null}
                {deleteDialogOpen?   <ConfirmationDialog
                    title="حذف الحدث"
                    confirmAction={
                    async ()=>{
                        const result = await deletePost(post.id);
                        if(result === 'success'){
                            const events= await fetchAllEvents();
                            setPostsList(events);
                            setResultMessage(result);
                            setDeleteDialogOpen(false);
                            setAlertOpen(true);
                            navigate(0);
                        }else{
                            setDeleteDialogOpen(false);
                            setResultMessage(result.message);
                            setDeleteDialogOpen(false);
                            setAlertOpen(true);
                        }
                    }}
                    denyAction={
                    ()=>{
                        setDeleteDialogOpen(false);
                    }}
                    message={"تأكيد حذف: "+post.title+"?"}
                    modalVisible={deleteDialogOpen}
                    setModalVisable={()=>{setDeleteDialogOpen(!deleteDialogOpen);}}
                  />:null}
            </div>
        </div>
    );
}