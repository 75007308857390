
import "./styles.css"
import Sidebar from "../../../Components/Sidebar/Sidebar";
import {Outlet} from "react-router-dom";
import {useState} from "react";

export default function ManagementPage({user}){

    const [isSideBarExpanded, setIsSideBarExpanded] = useState(false);
    const handleClick = ()=>{
        setIsSideBarExpanded(!isSideBarExpanded);
    }



    return(
        <div className={isSideBarExpanded? "management-page-wrapper":"management-page-wrapper-closed"}>

            <div className={isSideBarExpanded ? "layout-sidebar":"layout-sidebar-closed"}>
                <Sidebar handleClick={handleClick} isSideBarExpanded={isSideBarExpanded} user={user}/>
            </div>

            <div className={isSideBarExpanded ? "layout-outlet":"layout-outlet-closed"}>
                <Outlet handleClick={handleClick} isSideBarExpanded={isSideBarExpanded}/>
            </div>
        </div>
    );
}