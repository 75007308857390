import './styles.css'
import {
    Box,
    Button,
    Checkbox, Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, useMediaQuery, useTheme
} from "@mui/material";
import {COLORS} from "../../../../constants/constants";
import {deleteCustomUser} from "../../../../Services/firebase";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import parse from "html-react-parser";
import React from "react";
import UsersTableUnit from "./UsersTableUnit";


export default function UsersTable({users}){

    return(
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table" className="users-table">
                <TableHead className="users-table-header">
                    <TableRow className="users-table-header-row" key="header">
                        <TableCell align="center"  style={{fontFamily:"Cairo",fontSize:"1rem",fontWeight:"bold"}}>
                              <Checkbox style={{color:`${COLORS.darkTextColor}`}} onChange={(event, checked)=>{
                                  if(checked){

                                  }else{

                                  }
                              }}/>
                        </TableCell>
                        <TableCell align="center"  style={{fontFamily:"Cairo",fontSize:"1rem",fontWeight:"bold"}}>
                            الإسم
                        </TableCell>
                        <TableCell align="center"  style={{fontFamily:"Cairo",fontSize:"1rem",fontWeight:"bold"}}>
                            البريد الالكتروني
                        </TableCell>
                        <TableCell align="center"  style={{fontFamily:"Cairo",fontSize:"1rem",fontWeight:"bold"}}>
                            الدور
                        </TableCell>
                        <TableCell align="center" style={{fontFamily:"Cairo",fontSize:"1rem",fontWeight:"bold"}}>
                            UID
                        </TableCell>
                        <TableCell align="center" style={{fontFamily:"Cairo",fontSize:"1rem",fontWeight:"bold"}}>

                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user) => (
                        <UsersTableUnit user={user}/>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}