import {useEffect, useState} from "react";
import {fetchMediaLibrary} from "../../../../../../Services/firebase";
import CircularProgressIndicator
    from "../../../../../../Components/CircularProgressIndicator/CircularProgressIndicator";
import {Grid} from "@mui/material";
import MediaItem from "./MediaItem";

export default function SelectMediaLibraryImage({selectedImage,setSelectedImage}){

    const [images,setImages] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

    useEffect(()=>{
        setIsLoading(true)
        fetchMediaLibrary().then((images)=>{
            setImages(images);
            setIsLoading(false);
        });
    },[])

    return(
        <div className="select-media-library-images-grid">
            {isLoading?<CircularProgressIndicator/>:<Grid container spacing={0}>
                {images && images.map((image,i)=>{
                    return(<MediaItem key={i} image={image} selectedImage={selectedImage} setSelectedImage={setSelectedImage}/>);
                })}
            </Grid>}
        </div>
    );
}