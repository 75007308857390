import "./styles.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faSignal,
    faClose,
    faThumbtack,
    faCog,
    faBorderStyle,
    faChartBar,
    faUsers, faCaretDown, faImage
} from "@fortawesome/free-solid-svg-icons";
import NavItem from "./NavItem";
import {COLORS} from "../../constants/constants";

export default function Sidebar({handleClick,isSideBarExpanded,user}){

    return(
        <div className={isSideBarExpanded ? "sidebar-container" : "sidebar-container-closed"} id="sidebar-container">
            <div className={isSideBarExpanded?"icon-wrapper":"icon-wrapper-closed"}>
                <FontAwesomeIcon icon={isSideBarExpanded? faCaretDown:faSignal} size="lg" color={COLORS.lightTextColor} onClick={handleClick} rotation={270}/>
            </div>
            <br/>
            <nav className="sidebar-nav">
                <NavItem  icon={faThumbtack} to="/edara/posts-page" label="الأحداث" isExpanded={isSideBarExpanded} state={{user:user}}/>
                <NavItem  icon={faImage} to="/edara/media-library" label="الوسائط" isExpanded={isSideBarExpanded} state={{user:user}}/>
                <NavItem  icon={faUsers} to="/edara/users" label="الفريق" isExpanded={isSideBarExpanded} state={{user:user}}/>
                <NavItem  icon={faChartBar} to="/edara/statistics" label="إحصائيّات" isExpanded={isSideBarExpanded} state={{user:user}}/>
                <NavItem  icon={faBorderStyle} to="/edara/appearance" label="التصميم" isExpanded={isSideBarExpanded} state={{user:user}}/>
                <br/><br/>
                <NavItem  icon={faCog} to="/edara/settings" label="الإعدادات" isExpanded={isSideBarExpanded} state={{user:user}}/>
            </nav>
        </div>
    );
}