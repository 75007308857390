import React, {useState} from "react";
import {
    Box,
    Modal,
    useMediaQuery, useTheme
} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import parse from "html-react-parser";
import {COLORS} from "../../constants/constants";

function TimelineItem  ({ data }) {
    let  convertedDate = data.date.toDate();
    const date =new Intl.DateTimeFormat('en-US', {day: '2-digit', month: '2-digit'}).format(convertedDate);
    const dateFlipped = date.split("/")
    const dateFinal = dateFlipped[1]+"/" + dateFlipped[0]+"/"+convertedDate.getFullYear();
    const [modalVisible,setModalVisible] = useState(false);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


     const style = {
         position: 'absolute',
         top: '50%',
         left: '50%',
         transform: 'translate(-50%, -50%)',
         width: isSmallScreen ? '90%' : '90%',
         height:isSmallScreen? "95%":"90%",
         bgcolor: COLORS.primaryShade2,
         border: '1px solid #b6d1e2',
         boxShadow: 24,
         p: 2,
         direction:"rtl",
         overflow:"auto",
         textAlign:"justify",
     };

    return (
        <div className="timeline-item">
            <div className="timeline-item-content">
                <h3>{data.title}</h3>
                <button  className="popup-arrow" onClick={()=>{setModalVisible(true)}}>قراءة المزيد </button>
                <Modal
                  open={modalVisible}
                  onClose={()=>{setModalVisible(false)}}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                      <div id="modal-modal-title">
                          <h2>
                              {data.title}
                          </h2>
                          <FontAwesomeIcon icon={faClose} color={COLORS.buttonColor} onClick={()=>{setModalVisible(false);}} size="xl"/>
                      </div>

                    <div id="modal-modal-description" sx={{ mt: 2 }} className="modal-post-content">
                        <h3 className="modal-modal-date">
                            {dateFinal}
                        </h3>

                        <div >
                            {parse(data.content)}
                        </div>

                    </div>
                  </Box>
                </Modal>
                <span className="circle"/>
                <div className="date-test">
                    <p>
                        {dateFinal}
                    </p>
                </div>
            </div>
        </div>
    );
}


export default TimelineItem