import React, {useState} from "react";
const TabNavItem = ({ id, title, activeTab, setActiveTab }) => {
    const now = new Date();
    const isLastYear = title === now.getFullYear().toString();
    const handleClick = () => {
        setActiveTab(id);
    };

    return (
        <div className="tab-nav-item-wrapper">
            <li onClick={handleClick} className={activeTab === id ? "active" : ""}>
                { title }
            </li>
            <div className={isLastYear ? "horizontal-separator-none":"horizontal-separator"}></div>
        </div>
    );
};
export default TabNavItem