import Select from "react-select";
import {Box, Button, Input, Modal, useMediaQuery, useTheme} from "@mui/material";
import React, {useState} from "react";
import {COLORS} from "../../../../constants/constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faEnvelope, faKey, faUser} from "@fortawesome/free-solid-svg-icons";
import Editor from "../Posts/Quill/Editor";
import {customSignOut, signUp} from "../../../../Services/firebase";
import {Form} from "react-router-dom";
import {faMailchimp} from "@fortawesome/free-brands-svg-icons";
import {useNavigate} from "react-router";

export default function UsersToolsBar(){

    const options = [  { value: 'تحديد', label: 'تحديد' },  { value: 'حذف', label: 'حذف' },];
    const [usersBulkAction,setUsersBulkAction]=useState('تحديد');
    const [showModal,setShowModal] = useState(false);
        const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [userFirstName,setUserFirstName] = useState('');
    const [userLastName,setUserLastName] = useState('');
    const [userEmail,setUserEmail] = useState('');
    const [userPassword,setUserPassword] = useState('');
    const [userConfPassword,setUserConfPassword] = useState('');
    const navigate = useNavigate();

    const style = {
         position: 'absolute',
         top: '50%',
         left: '50%',
         transform: 'translate(-50%, -50%)',
         width: isSmallScreen ? '90%' : '30%',
         bgcolor: COLORS.primaryShade2,
         border: '.2rem solid #b6d1e2',
         boxShadow: 24,
         p: 2,
         direction:"rtl",
         overflow:"auto",
         textAlign:"center",
     };


    const handleFormSubmit = async (event) => {
        event.preventDefault();
        var result = await signUp(userEmail,userPassword,userFirstName,userLastName);
        setShowModal(false);
        setUserEmail("")
        setUserPassword("")
        setUserConfPassword("")
        setUserFirstName("")
        setUserLastName("")
        window.alert(result);
        await customSignOut();
        navigate("/login");
    };

    return (
        <div className="users-tools-bar">
            <div className="add-new-user-button">
                <Button onClick={()=>{
                    setShowModal(!showModal);
                }}>إضافة مستخدم</Button>
                <Modal open={showModal} onClose={()=>{setShowModal(false)}}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>

                      <div id="modal-modal-title">
                          <h2>إضافة مستخدم جديد</h2>
                          <FontAwesomeIcon icon={faClose} onClick={()=>{setShowModal(false)}} cursor="pointer"/>
                      </div>
                      <form id="modal-modal-description" className="add-new-user-form" onSubmit={handleFormSubmit}>
                          <div>
                              <FontAwesomeIcon icon={faUser}/>
                              <Input value={userFirstName} onChange={(event)=>{
                                  setUserFirstName(event.target.value);
                              }} className="firstname-field form-item" type="text" aria-label="الإسم" title="الإسم" placeholder="الإسم"  required/>
                          </div>

                          <div>
                              <FontAwesomeIcon icon={faUser}/>
                              <Input value={userLastName} onChange={(event)=>{
                                  setUserLastName(event.target.value);
                              }} className="lastname-field form-item" type="text" aria-label="العائلة" title="العائلة" placeholder="العائلة"  required/>
                          </div>

                          <div>
                              <FontAwesomeIcon icon={faEnvelope}/>
                              <Input value={userEmail} onChange={(event)=>{
                                  setUserEmail(event.target.value);
                              }} className="email-field form-item" type="email" aria-label="البريد الالكتروني" title="البريد الالكتروني" placeholder="البريد الالكتروني"  required/>
                          </div>

                          <div>
                              <FontAwesomeIcon icon={faKey}/>
                              <Input value={userPassword} onChange={(event)=>{
                                  setUserPassword(event.target.value);
                              }} className="password-field form-item" type="password" aria-label="كلمة السر" title="كلمة السر" placeholder="كلمة السر"  required/>
                          </div>

                          <div>
                              <FontAwesomeIcon icon={faKey}/>
                              <Input value={userConfPassword} onChange={(event)=>{
                                  setUserConfPassword(event.target.value);
                              }} className="confpassword-field form-item" type="password" aria-label="كلمة السر" title="كلمة السر" placeholder="تأكيد كلمة السر"  required/>
                          </div>
                          <Button type="submit">
                              إضافة
                          </Button>
                </form>
                  </Box>

                </Modal>
            </div>
            <div className="users-bulk-delete">
                <div className="users-bulk-select">
                    <Select value={usersBulkAction} options={options} placeholder="تحديد" onChange={(event)=>{
                        setUsersBulkAction(event)
                    }}/>
                </div>
                <div >
                    <Button>تطبيق</Button>
                </div>
            </div>
        </div>
    );
}