import React from 'react';
import {Helmet,HelmetProvider} from "react-helmet-async";

const MetaHead = ({title,description,type})=>{
    return(
        <HelmetProvider>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta property="og:title" content={title} />
                <meta property="og:type" content={type} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/turbinestimeline.appspot.com/o/public%2FlogoFinal.png?alt=media&token=ada33b0a-f17e-43dc-8855-37f44eeadbf1"/>
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/turbinestimeline.appspot.com/o/public%2FlogoFinal.png?alt=media&token=ada33b0a-f17e-43dc-8855-37f44eeadbf1" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
        </HelmetProvider>
    );
}

export default MetaHead